.wrap-blur {
  padding: 1em;
  background: rgba(226, 97, 51, 0.9);
  backdrop-filter: blur(90%);
  backdrop-filter: saturate(80%);
  border-radius: 5px;
  box-shadow: 0px 9px 22px 1px rgba(0, 0, 0, 0.25);
}

.form {
  display: block;
}

.form-area {
  margin: 2em 0 1.5em;
  width: 100%;
  text-align: center;
}

.form .field:last-child {
  margin: 0;
}

.field {
  margin: 0 0 1em;
}

.input {
  font-size: 16px;
  padding: 1em;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  font-weight: bold;
}

.button {
  font-size: 16px;
  padding: 1em;
  background: #32b377;
  color: #ffffff;
  border: 1px solid #42b377;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-in;
  font-weight: bold;
}

.button--disabled {
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #333333;
  cursor: default;
  opacity: 0.8;
}

/* Radio toggle switch */

.switch {
  width: 100%;
  display: inline-block;
  border-radius: 5px;
  height: 53px;
  padding: 4px;
  position: relative;
  zoom: 1;
  background: rgba(255, 255, 255, 0.25);
}
.switch:before,
.switch:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.switch:after {
  clear: both;
}
.switch label {
  float: left;
  width: 50%;
  position: relative;
  z-index: 2;
  line-height: 46px;
  cursor: pointer;
  color: white;
  text-align: center;
  border-radius: 5px;
}
.switch input[type="radio"] {
  display: none;
}
.switch input[type="radio"]:checked + label {
  color: white;
  font-weight: bold;
}
.switch span {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  display: block;
  border-radius: 5px;
  background-color: #121310;
  transition: all 0.2s ease;
}
.switch span.right {
  left: 50%;
  border-radius: 5px;
  transition: all 0.2s ease;
}

.field.field--radio {
  margin: 0;
}

@media only screen and (min-width: 920px) {
  .field {
    display: flex;
    align-items: center;
    margin-right: 1em;
    margin-bottom: 0;
  }

  .field.field--radio {
    display: flex;
    margin: 0 0 1em;
  }

  .field.field--shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .field--radio label {
    cursor: pointer;
  }

  .wrap-blur {
    padding: 1.5em;
  }

  .form-area {
    width: auto;
  }

  .form {
    z-index: 2;
    display: flex;
  }

  .input {
    width: auto;
  }

  .button {
    width: 200px;
    outline: none;
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-2px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(4px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-8px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(8px, 0, 0);
    }
  }

  @keyframes blink {
    0% {
      text-shadow: 0 0 15px red;
    }
    50% {
      text-shadow: none;
    }
    100% {
      text-shadow: 0 0 15px red;
    }
  }
}

@media only screen and (min-width: 1100px) {
  .form-area {
    margin: 6em 0 1.5em;
  }
  .input {
    width: 300px;
  }

  .field.field--radio {
    /* display: block; */
    margin: 0 auto 1em;
    position: absolute;
    bottom: 5em;
    width: 500px;
    left: 50%;
    margin-left: -250px;
  }
}
