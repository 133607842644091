.background {
  z-index: 0;
  position: fixed;  /* TODO: fixed */
  top: -50%; 
  left: -50%; 
  width: 200%; 
  height: 200%;
}

.background__image {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  min-width: 50%;
  height: 100vh;
}

.page {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
}

.App-header {
  padding: 2em 0;
  text-align: center;
}

.title--large {
  font-size: 36px;
  margin-top: 0;
  font-weight: 100;
  line-height: 1.2em;
}

.title {
  font-size: 30px;
  line-height: 1.3em;
}

.p {
  font-size: 16px;
  line-height: 1.6em;
  color: #ffffff;
  text-decoration: none;
}

.footer .p:hover {
  color: #dddddd;
}

.p--large {
  font-size: 18px;
  margin: auto;
  line-height: 1.6em;
  font-weight: bold;
}

.p--small {
  font-size: 12px;
  line-height: 1.6em;
  max-width: 600px;
  text-align: center;
}

.fold {
  height: auto;
  margin-bottom: 2em;
}

.content {
  max-width: 700px;
  text-align: center;
}

.divider svg {
  position:absolute;
  left:0;
  width:100%;
  height:100px;
  display:block;
}

.divider--top svg {
  top: -99px;
}

.divider--bottom svg {
  bottom: -99px;
}

.footer {
  padding: 2em;
  text-align: center;
}

@media only screen and (min-width: 767px) {  
  .fold {
    height: calc(100vh - 56px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    font-size: 36px;
    line-height: 1.3em;
  }
  .title--large {
    font-size: 42px;
  }
  .App-header {
    padding: 2.5em 0 0;
  }
  .footer {
    padding: 3em;
  }
}

@media only screen and (min-width: 1500px) {
  .title {
    font-size: 42px;
    line-height: 1.3em;
  }
  .title--large {
    font-size: 52px;
    line-height: 1.2em;
  }
  .p--large {
    font-size: 19px;
    line-height: 1.7em;
  }
}
