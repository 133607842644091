@font-face {
  font-family: 'avenirregular';
  src: url('./assets/fonts/avenir-web.woff2') format('woff2'),
    url('./assets/fonts/avenir-web.woff') format('woff'),
    url('./assets/fonts/avenir-web.otf') format('otf'),
    url('./assets/fonts/avenir-web.ttf') format('truetype'),
    url('./assets/fonts/avenir-web.svg#avenirregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'quarionbold_demo';
  src: url('./assets/fonts/quarion_bold_demo.eot');
  src: url('./assets/fonts/quarion_bold_demo.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/quarion_bold_demo.woff2') format('woff2'),
    url('./assets/fonts/quarion_bold_demo.woff') format('woff'),
    url('./assets/fonts/quarion_bold_demo.ttf') format('truetype'),
    url('./assets/fonts/quarion_bold_demo.svg#quarionbold_demo') format('svg');
  font-weight: normal;
  font-style: normal;
}




@font-face {
  font-family: 'quarionbook_demo';
  src: url('./assets/fonts/quarion_book_demo.eot');
  src: url('./assets/fonts/quarion_book_demo.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/quarion_book_demo.woff2') format('woff2'),
    url('./assets/fonts/quarion_book_demo.woff') format('woff'),
    url('./assets/fonts/quarion_book_demo.ttf') format('truetype'),
    url('./assets/fonts/quarion_book_demo.svg#quarionbook_demo') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #ffffff;
  font-family: quarionbook_demo, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2.4em;
}

input {
  font-family: quarionbook_demo, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

